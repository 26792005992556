import React from "react";

// import MainFooter from "components/Footer/MainFooter";
import MainNavigation from "../components/Navigation";

export default function TermsCondition() {
  return (
    <div>
      <MainNavigation />

      <section className="my-4 py-3 md:py-4 px-4 md:px-20 md:mt-20">
        <div className="container max-w-[1280px] mx-auto">
          <h2 className="text-2xl md:text-3xl text-blackText font-semibold mb-10">
            Terms and Conditions
          </h2>
          <p className="mb-5 opacity-80">
            The access and use of this platform, the “Careplanet Energies” mobile application
            or web app, is subject to the following terms and conditions of use
            ("Conditions" and/or "Terms"). If you do not agree to abide by these
            Conditions, please do not register yourself or proceed to use this
            application any further.
          </p>
          <p className="mb-5 opacity-80">
            Careplanet Energies is an initiative of CAREPLANET GROUPS PLC (“CAREPLANET GROUPS”).
            CAREPLANET GROUPSmay modify these Terms at any time without notice to you
            by posting revised Terms on its website (
            <a href="www.Careplanet Energies.ng" className="text-primary">
              www.Careplanet Energies.ng
            </a>
            ) and/or on this mobile application or web app. Your use of this
            mobile application or web app constitutes your binding acceptance of
            these Terms, including any modifications that CAREPLANET GROUPSmakes from
            time to time. Some of the services may be subject to additional
            posted conditions. Your use of those Services is subject to those
            conditions, which are incorporated into these Terms by reference. In
            the event of an inconsistency between these Terms and any additional
            posted conditions, the provisions of the additional conditions shall
            prevail.
          </p>
          <p className="mb-5 opacity-80">
            The term Careplanet Energies Agent and Careplanet Energies may and will be used
            interchangeably and bear the same meaning and relevance.
          </p>
          <p className="mb-5 opacity-80">
            CAREPLANET GROUPShas the right, but not the obligation, to take any of
            the following actions in its sole discretion at any time and for any
            reason without giving you any prior notice:
          </p>
          <ul className="list-decimal list-inside mb-8">
            <li className="mb-4 opacity-80">
              Restrict, suspend, or terminate your access to all or any part of
              its Services;
            </li>
            <li className="mb-4 opacity-80">
              Change, suspend, or discontinue all or any part of its Services;
            </li>
            <li className="mb-4 opacity-80">
              Establish general practices and limits concerning use of its
              Services and mobile application or web app.
            </li>
            <li className="mb-4 opacity-80">
              You agree that CAREPLANET GROUPSwill not be liable to you or any third
              party for taking any of these actions.
            </li>
          </ul>
          <p className="mb-5 opacity-80">
            You irrevocably and unconditionally agree, confirm, undertake and
            accept these terms, and other terms and conditions on registration,
            for access to or use of any service, contents, bill payment
            services, airtime recharge services, mobile money services, games or
            materials. Any amendment to these terms, and other terms and
            conditions from time to time shall be deemed to be accepted by you
            and shall be an integral part of the original terms, and other terms
            and conditions.
          </p>
          <p className="mb-5 opacity-80">
            Careplanet Energies is an open-loop prepaid/stored-value instrument and linked to
            your registered Mobile Number. The value in the stored-value account
            can be used by you for purchase of goods/or services, payment of
            bills, purchase of prepaid services such as airtime, electricity,
            Internet bundles and so forth from any merchant
            associated/registered with Careplanet Energies. Your correspondence or related
            activities with third parties, including payment transactions and
            goods-delivery transactions, are solely between you and that third
            party.
          </p>
          <p className="mb-5 opacity-80">
            Any account not transacting for 6 months or more may be treated as
            expired and the value may be forfeited. You agree that CAREPLANET GROUPS
            will not be responsible or liable for any loss or damage of any sort
            incurred as the result of any of your transactions with third
            parties. Any questions, complaints, or claims related to any product
            or service should be directed to the appropriate vendor.
          </p>
          <h3 className="text-lg text-primary mb-4 font-medium">
            Contest, Competitions and Events
          </h3>
          <p className="mb-5 opacity-80">
            If a contest, competition or event is organized by CAREPLANET GROUPSthen
            it shall be governed by the terms stated herein in addition to other
            terms and conditions which may be specifically applied per contest,
            competition or event. CAREPLANET GROUPSemployees, relatives, affiliates,
            and friends cannot participate in any contest, competition or event
            and shall stand disqualified with immediate effect even if
            participated and shall be liable to return the prize or benefit
            received and at its sole discretion pay penalty, damages and
            compensation and indemnify CAREPLANET GROUPSagainst any claims, expenses
            or liability from any person, entity or third party by this act or
            omission of the employees or relatives.
          </p>
          <p className="text-lg text-primary mb-4 font-medium">
            General Terms for use of Careplanet Energies Application (Careplanet Energies)
          </p>
          <ul className="list-decimal list-inside mb-8">
            <li className="mb-4 opacity-80">
              Careplanet Energies can only be obtained by individuals above 18 years of age.
            </li>
            <li className="mb-4 opacity-80">
              These terms and conditions as well as any amendments thereof are
              part of a contract between you and CAREPLANET GROUPSand govern the use
              of Careplanet Energies.
            </li>
            <li className="mb-4 opacity-80">
              CAREPLANET GROUPSshall not be liable/responsible for any defect in the
              product/merchandise/goods or services availed through Careplanet Energies.
              CAREPLANET GROUPSdoes not warranty against any damage or defect for the
              above mentioned transactions.
            </li>
            <li className="mb-4 opacity-80">
              Careplanet Energies mobile application or web app is GPRS/EDGE/3G/Internet
              dependent and cannot be availed by you in the event of
              GPRS/EDGE/3G/Internet failure and/or download error. CAREPLANET GROUPS
              is not responsible in any manner whatsoever for non-provisioning
              of the services due to GPRS/EDGE/3G/Internet failure/connectivity
              failure/download error.
            </li>
            <li className="mb-4 opacity-80">
              You understand and agree that PIN/password and other information
              with respect to your Careplanet Energies account are confidential information.
              You agree to immediately change the PIN on receipt and to continue
              changing the PIN periodically (recommended at least once every 30
              days). You agree that CAREPLANET GROUPSshall not be responsible if you
              share your PIN and other confidential information about your
              Careplanet Energies account with any person or fail to abide by the advice
              given in this paragraph about changing PIN periodically.
            </li>
            <li className="mb-4 opacity-80">
              You understand and agree that your Careplanet Energies account is linked to
              your registered Mobile Number or Email. CAREPLANET GROUPSwill not be
              held liable for unauthorized use of Careplanet Energies on account of
              loss/theft/misplacement of Mobile Number and/or SIM unless you
              have informed Careplanet Energies customer care about such
              loss/theft/misplacement. It must be noted you shall continue to be
              liable for any unauthorized use of your Careplanet Energies account until you
              inform Careplanet Energies Customer care about such loss/theft/misplacement and
              request for blocking your Careplanet Energies account is executed. CAREPLANET GROUPS
              shall not be responsible for any misuse of Careplanet Energies.
            </li>
            <li className="mb-4 opacity-80">
              The security of your personal information is ensured by the
              encryption of the transmission of sensitive information using
              secure socket layer technology (SSL). We follow generally accepted
              industry standards to protect the personal information submitted
              to us, both during transmission and once we receive it. No method
              of transmission, or method of electronic storage, is 100% secure.
              Therefore, we cannot guarantee its absolute security.
            </li>
            <li className="mb-4 opacity-80">
              We will retain your information for as long as your account is
              active or as needed to provide you services. If you wish to cancel
              your account or request that we no longer use your information to
              provide you services contact us at support@Careplanet Energies.ng . We will
              retain and use your information as necessary to comply with our
              legal obligations, resolve disputes, and enforce our agreement.
            </li>
            <li className="mb-4 opacity-80">
              CAREPLANET GROUPSreserves the right to amend or withdraw Careplanet Energies without
              any prior notice. Careplanet Energies shall not be responsible for the Agent
              not having enough funds in their wallet. Careplanet Energies is not obligated
              to provide Services to the Customers in case the Agent does not
              have the required denomination.
            </li>
            <li className="mb-4 opacity-80">
              If CAREPLANET GROUPSrequest registration information from you, you will
              provide CAREPLANET GROUPSwith true, accurate, current, and complete
              information. You will promptly update your registration to keep it
              accurate, current, and complete. If CAREPLANET GROUPSissue you a
              password, you may not reveal it to anyone. You are responsible for
              maintaining the confidentiality of your accounts and passwords.
              You agree to immediately notify CAREPLANET GROUPSof any unauthorized
              use of your passwords or accounts or any other breach of security.
              You also agree to exit from your accounts at the end of each
              session. CAREPLANET GROUPSwill not be responsible for any loss or
              damage that may result if you fail to comply with these
              requirements.
            </li>
            <li className="mb-4 opacity-80">
              The technology and the software underlying in this mobile
              application or web app and the Services is the property of ENOVATE
              LAB, its affiliates, and its partners or licensors or associates.
              You agree not to copy, modify, rent, lease, loan, sell, assign,
              distribute, reverse engineer, grant a security interest in, or
              otherwise transfer any right to the technology or software
              underlying its web-sites or the Services. You agree not to modify
              the software underlying in any manner or form or to use modified
              versions of such software, including (without limitation) for the
              purpose of obtaining unauthorized access to the CAREPLANET GROUPS
              backend.
            </li>
            <li className="mb-4 opacity-80">
              Use of CAREPLANET GROUPSServices is subject to existing laws and legal
              processes. Violators will be prosecuted to the maximum extent
              possible. All rights reserved. Any rights not expressly granted
              herein are reserved. Nothing contained in these Terms shall limit
              its right to comply with governmental, court, and law-enforcement
              requests or requirements relating to your use of CAREPLANET GROUPS
              Services. Contact support@Careplanet Energies.ng with problems or questions, as
              appropriate.
            </li>
          </ul>
        </div>
      </section>

      {/* <MainFooter /> */}
    </div>
  );
}
