import { useContext } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import AuthContext from "../context/AuthContext";

const PrivateRoute = () => {
  const { user, loading } = useContext(AuthContext);
  const location = useLocation();


  console.log("PrivateRoute - user:", user);
  console.log("PrivateRoute - loading:", loading);



  if (!user || loading ) {
    console.log("Redirecting to login...");
    return <Navigate to="/auth/login" replace state={{ from: location }} />;
  }

  console.log("Rendering Outlet...");
  return <Outlet />;

  // return !user || loading || user.role !== "Agent" ? (
  //   <Navigate to="/auth/login" replace state={{ from: location }} />
  // ) : (
  //   <Outlet />
  // );


  
};

export default PrivateRoute;
