/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/

import React, {useState, useEffect, useContext} from 'react'
import AuthContext from '../../context/AuthContext'
import { Disclosure } from '@headlessui/react'
import { LockClosedIcon } from '@heroicons/react/24/solid'
import axios from '../../utils/axios'
import { formatCurrency, formatNumber } from '../../utils/format'
import { useForm } from "react-hook-form";
import SpinnerIcon from '../icons/SpinnerIcons'

import { Link, useNavigate } from 'react-router-dom'
// import PaymentComponent from '../MonnifyPayment'
import { loadMonnifyScript } from '../../utils/loadMonnifyScript'
import SuccessfulTrx from '../modals/SuccessfulTrx'
import ErrorTrxModal from '../modals/ErrorModal'

const subtotal = '$108.00'
const discount = { code: 'CHEAPSKATE', amount: '$16.00' }
const taxes = '$9.92'
const shipping = '$8.00'
const total = '$141.92'



const formatCurrencyNg = (number) => {
    return `₦${number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
  };


export default function Form() {
    const [loading, setLoading] = useState(false);
    const [loadingShares, setLoadingShare] = useState(false);
    const [quantity, setQuantity] = useState('');
    const [amount, setAmount] = useState('');
    const [formattedAmount, setFormattedAmount] = useState(''); // New state for formatted amount
    const [message, setMessage] = useState(null);
    const [availableShares, setAvailableShares] = useState(null);
    const [error, setError] = useState(null);
    const [purchaseMade, setPurchaseMade] = useState(false); // New state to track purchases
    const [calculatedValue, setCalculatedValue] = useState(0); // New state for calculated value
    const [agreeTos, setAgreeTos] = useState(false);

    const navigate = useNavigate();

  const [modalOpen, setModalOpen] = useState(false);
  const [erroModalOpen, setErrorModalOpen] = useState(false);



  // const openDrawer = () => setDrawerIsOpen(true);

  // const closeDrawer = () => setDrawerIsOpen(false);


  const handleSuccessModalClose = () => {
    setModalOpen(false);
    navigate("/transactions");
  };
  const handleErrorModalClose = () => {
    setErrorModalOpen(false);
  };

    // const [currentTransaction, setCurrentTransaction] = useState()

    const PRICE_PER_SHARE = 4.50;

      const { user } = useContext(AuthContext);

//   const {
//     register,
//     handleSubmit,
//     watch,
//     formState: { errors },
//   } = useForm();

//   console.log(user)

    useEffect(() => {
      const fetchAvailableShares = async () => {
        try {
            setLoadingShare(true)
          const response = await axios.get('/shares/available', {
            withCredentials: true,
            credentials: "include",
            method: "GET",
            // headers: {
            //   Authorization: `Bearer ${user.accessToken}`
            // }
          });
          setAvailableShares(response.data.available);
          setPurchaseMade(false); // Reset purchase tracking state
          setLoadingShare(false)

        } catch (err) {
          setError(err.response ? err.response.data.message : 'Error fetching available shares');
          setLoadingShare(false);
        }
      };
  
      fetchAvailableShares();
    }, [purchaseMade]);



    const handlePaymentSuccess = async (transactionResponse, transaction) => {

       

        try {
        //   const token = localStorage.getItem('token'); // Assuming you're storing JWT in localStorage
          const response = await axios.post('/shares/payment-success', 
          {
            transactionId: transaction._id,
            transactionReference: transactionResponse.transactionReference,
            paymentReference: transactionResponse.paymentReference,
            amount: transactionResponse.authorizedAmount,
            // paymentMethod: transactionResponse.paymentMethod,
            paymentStatus: transactionResponse.status,
            // customerEmail: transactionResponse.customerEmail,
            // customerName: transactionResponse.customerName,
            // fee: transactionResponse.fee,
            // metaData: transactionResponse.metaData
          },
            {
                withCredentials: true,
                credentials: "include",
            //   headers: {
            //     Authorization: `Bearer ${token}`
            //   }
            }
          );
          setModalOpen(true);
          console.log('Payment success response:', response.data);
        } catch (err) {
          console.error('Error handling payment success:', err.response ? err.response.data.message : err.message);
        }
      };


    const initializePayment = (transaction) => {
      const additionalFee = transaction.totalAmount * 0.01;
        window.MonnifySDK.initialize({
          // fee: additionalFee,
          amount: parseFloat(transaction.totalAmount),
          currency: "NGN",
        //   reference: new String((new Date()).getTime()),
        referrence: transaction._id,
          customerFullName: `${user.firstName}  ${user.lastName}`, // You may want to replace this with dynamic data
          customerEmail: user.email, // You may want to replace this with dynamic data
          apiKey: "MK_TEST_73GAKMWJQ9",
          // apiKey: "MK_PROD_B92MTH5HSX",
          contractCode: "4966293752",
          // contractCode: "978551500435",
          paymentDescription: "Share Purchase",
          metadata: {
            "name": "Customer",
            "transaction": "share purchase"
          },
        
        paymentMethods: [
            "CARD",
            "ACCOUNT_TRANSFER",
            "USSD",
            // "PHONE_NUMBER"
          ],
          onLoadStart: () => {
            console.log("Loading has started");
          },
          onLoadComplete: () => {
            console.log("SDK is UP");
          },

          

          onComplete: function(response) {
            //Implement what happens when the transaction is completed.
            // console.log(response);
            handlePaymentSuccess(response, transaction)
        },


        //   onComplete: async function(response) {
        //     console.log(response);
        //     setMessage('Payment successful!');
        //     setPurchaseMade(true); 
        //     handlePaymentSuccess(response);
        //   },
          onClose: function(data) {
            console.log(data);
          }
        });
      };


      


  // Event handlers to keep the quantity and amount fields synchronized
  const handleQuantityChange = (e) => {
    const newQuantity = e.target.value;
    setQuantity(newQuantity);
    const calculatedAmount = (newQuantity * PRICE_PER_SHARE).toFixed(2);
    setAmount(calculatedAmount);
    setFormattedAmount(formatCurrencyNg(calculatedAmount));
  };

  const handleAmountChange = (e) => {
    const input = e.target.value.replace(/[₦,]/g, ''); // Remove formatting characters
    const newAmount = parseFloat(input);
    if (!isNaN(newAmount)) {
      setAmount(newAmount);
      const calculatedQuantity = (newAmount / PRICE_PER_SHARE).toFixed(2);
      setQuantity(calculatedQuantity);
      setFormattedAmount(formatCurrencyNg(newAmount));
    } else {
      setFormattedAmount(''); // Clear formatted amount if input is invalid
    }
  };

    // Update the calculated value whenever the quantity changes
  useEffect(() => {
    const value = quantity * 4.50;
    setCalculatedValue(value);
  }, [quantity]);

    const handleOnSubmit = async (event) => {
        event.preventDefault();
        setMessage(null);
        setError(null);
    
        try {
            setLoading(true);
          const response = await axios.post('/shares/buy', 
            { 
              quantity: parseInt(quantity, 10), // Ensure quantity is a number
              description: 'Buy Shares'
            }, 
            {
            //   headers: {
            //     Authorization: `Bearer ${token}`
            //   }
            withCredentials: true,
            credentials: "include",
            method: "GET",
            }
          );

          setMessage(response?.data?.message);
          setPurchaseMade(true); 
          setLoading(false);
          console.log(response)

      // Load Monnify script and initialize payment
      loadMonnifyScript()
        .then(() => {
          initializePayment(response?.data?.transaction);
        })
        .catch(err => {
          setError('Failed to load payment gateway. Please try again.');
        });



      
        } catch (err) {
          setError(err.response ? err.response.data.message : 'Error buying shares');
          setLoading(false);
          // console.log(err)
          setErrorModalOpen(true);

        }
      };


   

  return (
    <>

{modalOpen && (<SuccessfulTrx
        open={modalOpen}
          // transaction={currentTransaction}
          // closeDrawer={closeDrawer}
          onClose={handleSuccessModalClose}
        />)}

{erroModalOpen && (<ErrorTrxModal
        open={erroModalOpen}
          // transaction={currentTransaction}
          // closeDrawer={closeDrawer}
          onClose={handleErrorModalClose}
          errorMessage={error}
        />)}
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-white">
        <body class="h-full">
        ```
      */}
      <main className="lg:min-h-full lg:overflow-hidden lg:flex lg:flex-row-reverse">
        <h1 className="sr-only">Checkout</h1>

        {/* Mobile order summary */}
        <section aria-labelledby="order-heading" className="bg-gray-50 px-4 py-6 sm:px-6 lg:hidden">
          <Disclosure as="div" className="max-w-lg mx-auto">
            {({ open }) => (
              <>
                <div className="flex items-center justify-between">
                  <h2 id="order-heading" className="text-lg font-medium text-gray-900">
                    Your Order
                  </h2>
                  <Disclosure.Button className="font-medium text-indigo-600 hover:text-indigo-500">
                    {open ? <span>Hide full summary</span> : <span>Show full summary</span>}
                  </Disclosure.Button>
                </div>

                <Disclosure.Panel>
               

                  <dl className="text-sm font-medium text-gray-500 mt-10 space-y-6">
                  <div className="flex justify-between">
                      <dt>Subtotal</dt>
                      <dd className="text-gray-900">{subtotal}</dd>
                    </div>
                    <div className="flex justify-between">
                      <dt>Subtotal</dt>
                      <dd className="text-gray-900">{subtotal}</dd>
                    </div>
                    <div className="flex justify-between">
                      <dt className="flex">
                        Discount
                        <span className="ml-2 rounded-full bg-gray-200 text-xs text-gray-600 py-0.5 px-2 tracking-wide">
                          {discount.code}
                        </span>
                      </dt>
                      <dd className="text-gray-900">-{discount.amount}</dd>
                    </div>
                    <div className="flex justify-between">
                      <dt>Taxes</dt>
                      <dd className="text-gray-900">{taxes}</dd>
                    </div>
                    <div className="flex justify-between">
                      <dt>Shipping</dt>
                      <dd className="text-gray-900">{shipping}</dd>
                    </div>
                  </dl>
                </Disclosure.Panel>

                <p className="flex items-center justify-between text-sm font-medium text-gray-900 border-t border-gray-200 pt-6 mt-6">
                  <span className="text-base">Total Shares</span>
                  <span className="text-base">{  loadingShares? <SpinnerIcon color='black'/> : `${formatNumber(availableShares?.totalShares)} Units`}</span>
                </p>
                <p className="flex items-center justify-between text-sm font-medium text-gray-900 border-t border-gray-200 pt-6 mt-6">
                  <span className="text-base">Available Shares</span>
                  <span className="text-base"> {  loadingShares? <SpinnerIcon color='black'/> : `${formatNumber(availableShares?.available)} Units`}</span>
                </p>
                <p className="flex items-center justify-between text-sm font-medium text-gray-900 border-t border-gray-200 pt-6 mt-6">
                  <span className="text-base">Current Price</span>
                  <span className="text-base">{  loadingShares? <SpinnerIcon color='black'/> : `₦${formatCurrency(availableShares?.currentPrice)}K`}</span>
                </p>

          
              </>
            )}
          </Disclosure>
        </section>

        {/* Order summary */}
        <section aria-labelledby="summary-heading" className="hidden w-1/3 max-w-md flex-col lg:flex">
          <h2 id="summary-heading" className="text-black sr-only">
            Order summary
          </h2>

          {/* <ul role="list" className="flex-auto overflow-y-auto divide-y divide-gray-200 px-6">
            {products.map((product) => (
              <li key={product.id} className="flex py-6 space-x-6">
            
               
              </li>
            ))}
          </ul> */}

          <div className="sticky bottom-0 flex-none bg-gray-200 border-t border-gray-200 rounded-lg p-6">
            {/* <form>
              <label htmlFor="discount-code" className="block text-sm font-medium text-gray-700">
                Discount code
              </label>
              <div className="flex space-x-4 mt-1">
                <input
                  type="text"
                  id="discount-code"
                  name="discount-code"
                  className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
                <button
                  type="submit"
                  className="bg-gray-200 text-sm font-medium text-gray-600 rounded-md px-4 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-indigo-500"
                >
                  Apply
                </button>
              </div>
            </form> */}
 <h2 id="" className="text-black sr-only">
            Order summary
          </h2>
            <dl className="text-sm font-medium text-gray-500 mt-10 space-y-6">
            <div className="flex justify-between text-gray-900">
                      <dt>Order Summary</dt>
                    </div>
              <div className="flex justify-between">
                <dt>Share Amount</dt>
                <dd className="text-gray-900">{formattedAmount}</dd>
              </div>
              <div className="flex justify-between">
                <dt >
                  Share Quantity
                  
                </dt>
                <dd className="text-gray-900">{quantity}</dd>

                {/* <dd className="text-gray-900">-{discount.amount}</dd> */}
              </div>
              <div className="flex justify-between">
                <dt>Total Payable</dt>
                <dd className="text-gray-900">{formattedAmount}</dd>
              </div>
              {/* <div className="flex justify-between">
                <dt>Shipping</dt>
                <dd className="text-gray-900">{shipping}</dd>
              </div> */}
              <div className="flex  items-center justify-between border-t-2 border-gray-300 text-gray-900 pt-6">
                <dt>Total</dt>
                <dd className="text-base">{formattedAmount}</dd>
              </div>
            </dl>
          </div>
        </section>

        {/* Checkout form */}
        <section
          aria-labelledby="payment-heading"
          className="flex-auto overflow-y-auto px-4 pt-12 pb-16 sm:px-6 sm:pt-16 lg:px-8 lg:pt-0 lg:pb-24"
        >
          <h2 id="payment-heading" className="sr-only">
            Payment and shipping details
          </h2>

          <div className="max-w-lg mx-auto lg:pt-8">
        
<div className='hidden lg:inline'>
<p className="flex items-center justify-between text-sm font-medium text-gray-900 border-t border-gray-200 pt-6 mt-6">
                  <span className="text-base">Total Shares</span>
                  <span className="text-base">{ loadingShares? <SpinnerIcon color='black'/> : `${formatNumber(availableShares?.totalShares)} Units`}</span>
                </p>
                <p className="flex items-center justify-between text-sm font-medium text-gray-900 border-t border-gray-200 pt-6 mt-6">
                  <span className="text-base">Available Shares</span>
                  <span className="text-base"> {  loadingShares? <SpinnerIcon color='black'/> : `${formatNumber(availableShares?.available)} Units`}</span>
                </p>
                <p className="flex items-center justify-between text-sm font-medium text-gray-900 border-t border-gray-200 pt-6 mt-6">
                  <span className="text-base">Current Price</span>
                  <span className="text-base">{  loadingShares? <SpinnerIcon color='black'/> : `₦${formatCurrency(availableShares?.currentPrice)}K`}</span>
                </p>
</div>



            <div className="w-full mt-6 border-t border-gray-200" />


            <form onSubmit={handleOnSubmit} className="mt-6">
              <div className="grid grid-cols-12 gap-y-6 gap-x-4">

                





                <div className="col-span-full">
                  <label htmlFor="card-number" className="block text-sm font-medium text-gray-700">
                    Enter Share Quantity
                  </label>
                  <div className="mt-1">
                    <input
                    value={quantity}
                      type="number"
                      id="card-number"
                      name="card-number"
                      autoComplete="cc-number"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    //   {...register("share", { required: true })}
                    // onChange={(e) => setQuantity(e.target.value)}
                    onChange={handleQuantityChange}
required

                    />
                  </div>
                </div>

              

                <div className="col-span-full relative mt-8">
              <div className="absolute inset-0 flex items-center" aria-hidden="true">
                <div className="w-full border-t border-gray-200" />
              </div>
              <div className="relative flex justify-center">
                <span className="px-4 bg-white text-sm font-medium text-gray-500">or</span>
              </div>
            </div>



            <div className="col-span-full">
                  <label htmlFor="card-number" className="block text-sm font-medium text-gray-700">
                Enter Shares Amount ₦
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                    //   value = { `₦${formatNumber(amount)}`}
                    value = {formattedAmount}
                      id="card-number"
                      name="card-number"
                      autoComplete="cc-number"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      onChange={handleAmountChange}

                    />
                  </div>
                </div>


                

            
              </div>


<div className='mt-6 flex space-x-2'> 
<div className="flex items-center h-5">
            <input
              id="agreeTos"
              name="agreeTos"
              type="checkbox"
              className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
              onChange={() => setAgreeTos(!agreeTos)}
            />

            </div>
            <label
              htmlFor="agreeTos"
              className="ml-2 block text-xs text-gray-900"
            >
              I have read, understood and I agree to Adashi’s
              <Link className="mx-1" to="/privacy-policy">
                <span className="text-primary">Privacy Policy,</span>
              </Link>
              and
              <Link className="ml-1" to="/terms-condition">
                <span className="text-primary">Terms and conditions</span>
              </Link>
              .
            </label>

</div>
             
          

              {/* <div className="mt-6 flex space-x-2">
                <div className="flex items-center h-5">
                  <input
                    id="same-as-shipping"
                    name="same-as-shipping"
                    type="checkbox"
                    defaultChecked
                    className="h-4 w-4 border-gray-300 rounded text-primary focus:primary"
                    onChange={() => setAgreeTos(!agreeTos)}

                  />
                </div>
                <label htmlFor="same-as-shipping" className="text-sm font-medium text-gray-900">
                 I have read and agreed to the terms and conditions of Careplant energies
                </label>
              </div> */}

              {/* <button
                type="submit"
                className="w-full mt-6 bg-secondary border border-transparent rounded-md shadow-sm py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Pay {total}
              </button> */}


              <button
              type="submit"
              className="w-full mt-6 flex items-center justify-center bg-black border border-transparent text-white rounded-md py-2 hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
              disabled={loading || !agreeTos}
            >
              <span className=" ">
              {loading ? <SpinnerIcon /> :  `Buy Share ₦ ${formatNumber(calculatedValue.toFixed(2))}`}

             

              </span>
              {/* <svg className="h-5 w-auto" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 20">
                <path d="M9.536 2.579c-.571.675-1.485 1.208-2.4 1.132-.113-.914.334-1.884.858-2.484C8.565.533 9.564.038 10.374 0c.095.951-.276 1.884-.838 2.579zm.829 1.313c-1.324-.077-2.457.751-3.085.751-.638 0-1.6-.713-2.647-.694-1.362.019-2.628.79-3.323 2.017-1.429 2.455-.372 6.09 1.009 8.087.676.99 1.485 2.075 2.552 2.036 1.009-.038 1.409-.656 2.628-.656 1.228 0 1.58.656 2.647.637 1.104-.019 1.8-.99 2.475-1.979.771-1.122 1.086-2.217 1.105-2.274-.02-.019-2.133-.828-2.152-3.263-.02-2.036 1.666-3.007 1.742-3.064-.952-1.408-2.437-1.56-2.951-1.598zm7.645-2.76v14.834h2.305v-5.072h3.19c2.913 0 4.96-1.998 4.96-4.89 0-2.893-2.01-4.872-4.885-4.872h-5.57zm2.305 1.941h2.656c2 0 3.142 1.066 3.142 2.94 0 1.875-1.142 2.95-3.151 2.95h-2.647v-5.89zM32.673 16.08c1.448 0 2.79-.733 3.4-1.893h.047v1.779h2.133V8.582c0-2.14-1.714-3.52-4.351-3.52-2.447 0-4.256 1.399-4.323 3.32h2.076c.171-.913 1.018-1.512 2.18-1.512 1.41 0 2.2.656 2.2 1.865v.818l-2.876.171c-2.675.162-4.123 1.256-4.123 3.159 0 1.922 1.495 3.197 3.637 3.197zm.62-1.76c-1.229 0-2.01-.59-2.01-1.494 0-.933.752-1.475 2.19-1.56l2.562-.162v.837c0 1.39-1.181 2.379-2.743 2.379zM41.1 20c2.247 0 3.304-.856 4.227-3.454l4.047-11.341h-2.342l-2.714 8.763h-.047l-2.714-8.763h-2.409l3.904 10.799-.21.656c-.352 1.114-.923 1.542-1.942 1.542-.18 0-.533-.02-.676-.038v1.779c.133.038.705.057.876.057z" />
              </svg> */}
            </button>

              <p className="flex justify-center text-sm font-medium text-gray-500 mt-6">
                <LockClosedIcon className="w-5 h-5 text-gray-400 mr-1.5" aria-hidden="true" />
                Payment details stored in plain text
              </p>
            </form>
          </div>
        </section>
      </main>
    </>
  )
}
