import logo from './logo.svg';
import './App.css';
// import Home1 from './pages/home/landing-pages/background_image_hero_with_alternating_features';
import Home from './pages/home/landing-pages/Home';
// import Home3 from './pages/home/landing-pages/simple_with_offset_screenshot_hero';
import PrivacyPolicy from './pages/privacyPolicy';
import TermsCondition from './pages/TermsConditions';
import {  BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import { Toaster } from "react-hot-toast";

import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from '@tanstack/react-query'

// import { QueryClientProvider, QueryClient } from "react-query";

import Dashboard from './pages/dashboard/Dashboard';
import AdminDashboard from './pages/admin/Dashboard';
import AllUsers from './pages/admin/AllUsers'

import Error404 from './pages/Error404';
import PrivateRoute from './routes/PrivateRoutes';
import Signup from './Auth/SignUp';
import Login from './Auth/Login';
import LoginAdmin from './pages/admin/Login'
import Transaction from './pages/transactiiion/Transaction';

export default function App() {
  // If you want to disable the animation just use the disabled `prop` like below on your page's component
  // return <AnimationRevealPage disabled>xxxxxxxxxx</AnimationRevealPage>;

  const queryClient = new QueryClient();
  return (
    <>
     <QueryClientProvider client={queryClient}>
      <Router>
      <Toaster position="top-center" toastOptions={{ duration: 10000 }} />

        <AuthProvider>

        <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route exact path="/terms" element={<TermsCondition />} />

            <Route path="/auth/signup" element={<Signup />} />
            <Route path="/auth/login" element={<Login />} />
            <Route path="/admin/login" element={<LoginAdmin />} />

            <Route path="*" element={<Error404 />} />
            


            <Route element={<PrivateRoute />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/admin-dashboard" element={<AdminDashboard />} />
            <Route path="/all-users" element={<AllUsers />} />


            <Route path="/transactions" element={<Transaction />} />

              {/* Add other protected routes here */}
               {/* <Route path="/verification" element={<AgentVerification />} />
              <Route path="/savers" element={<Savers />} />
              <Route path="/transactions" element={<Transactions />} />
              <Route path="/commissions" element={<AgentCommissions />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/groups" element={<GroupsSavings />} />
              <Route path="/groups/:id" element={<GroupDetails />} /> */}
            </Route>
          </Routes>
          
      
        </AuthProvider>
      </Router>
    </QueryClientProvider>
    </>
  );
}

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// export default App;
