// require('dotenv').config()


function getConstants() {
    if (process.env.REACT_APP_CAREPLANET_ENV  === "production") {
      return {
        baseUrl: "https://api.careplanetgroup.com/api/v1",
      };
    }
    if (process.env.REACT_APP_CAREPLANET_ENV  === "staging") {
      return {
        baseUrl: "https://api.careplanetgroup.com/api/v1",
      };
    }
  
    return {
      baseUrl: "https://api.careplanetgroup.com/api/v1",
      // baseUrl: "http://localhost:4000/api/v1",

    };
  }
  
  const constants = getConstants();
  export default constants;