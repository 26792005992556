/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  const colors = require('tailwindcss/colors')
  
  module.exports = {
    // ...
    theme: {
      extend: {
        colors: {
          cyan: colors.cyan,
        },
      },
    },
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import React, { Fragment, useState, useContext, useEffect } from "react";
import {
  Dialog,
  Menu,
  Transition,
  DialogPanel,
  TransitionChild,
  TransitionRootProps,
} from "@headlessui/react";
import {
  BellIcon,
  ClockIcon,
  HomeIcon,
  Bars3CenterLeftIcon,
  QuestionMarkCircleIcon,
  ScaleIcon,
  ShieldCheckIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import {
  BanknotesIcon,
  CheckCircleIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  BuildingOfficeIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/solid";

import AuthContext from "../../context/AuthContext";
import axios from "../../utils/axios";
import { formatDate, formatNumber } from "../../utils/format";
import { Link } from "react-router-dom";
import SpinnerIcon from "../../components/icons/SpinnerIcons";
import logo from "../../images/Logo.png";

const navigation = [
//   { name: "Dashboard", to: "/dashboard", icon: HomeIcon, current: false },
  {
    name: "All Transactions",
    to: "/admin-dashboard",
    icon: ClockIcon,
    current: false,
  },
  { name: "All Users", to: "/all-users", icon: ScaleIcon, current: true },
  //   { name: 'Cards', to: '#', icon: CreditCardIcon, current: false },
  //   { name: 'Recipients', to: '#', icon: UserGroupIcon, current: false },
  //   { name: 'Reports', to: '#', icon: DocumentChartBarIcon, current: false },
];
const secondaryNavigation = [
  //   { name: 'Settings', to: '#', icon: CogIcon },
  { name: "Help", to: "#", icon: QuestionMarkCircleIcon },
  { name: "Terms & Condition", to: "/terms", icon: ShieldCheckIcon },
];

// const transactions = [
//   {
//     id: 1,
//     name: 'Payment to Molly Sanders',
//     href: '#',
//     amount: '$20,000',
//     currency: 'USD',
//     status: 'success',
//     date: 'July 11, 2020',
//     datetime: '2020-07-11',
//   },
// ]
const statusStyles = {
  successful: "bg-green-100 text-green-800",
  verified: "bg-green-100 text-green-800",

  processing: "bg-yellow-100 text-yellow-800",
  failed: "bg-gray-100 text-gray-800",
  initiated: "bg-orange-200 text-black",
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function AllUsers() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const { user } = useContext(AuthContext);
  const [users, setUsers] = useState([]);
  const [userCounts,   setUserCount] = useState();

  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
    const fetchTransactions = async () => {
      try {
        const response = await axios.get("/admin/", {
          //   headers: {
          //     Authorization: `Bearer ${token}`
          //   }
          withCredentials: true,
          credentials: "include",
        });

        console.log(response);
        setUsers(response?.data?.data?.users);
        setUserCount(response?.data?.results);

        setLoading(false);
        // console.log(response)
      } catch (err) {
        setError(
          err.response
            ? err.response.data.message
            : "Error fetching transactions"
        );
        setLoading(false);
      }
    };

    fetchTransactions();
  }, []);

  const downloadCertificate = async (transactionId) => {
    const response = await axios.get(
      `/shares/transactions/${transactionId}/certificate`,
      {
        //   headers: {
        //     Authorization: `Bearer ${token}`
        //   },
        withCredentials: true,
        credentials: "include",
        responseType: "blob",
      }
    );

    if (!response.data) {
      return alert("transacton not successful");
    }

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `certificate_${transactionId}.pdf`);
    document.body.appendChild(link);
    link.click();
  };

  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-gray-100">
        <body class="h-full">
        ```
      */}
      <div className="min-h-full">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 flex z-40 lg:hidden"
            onClose={setSidebarOpen}
          >
            <TransitionChild
              as="div"
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              //   leaveTo="opacity-0"
            >
              <DialogPanel className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </TransitionChild>
            <TransitionChild
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-cyan-700">
                <TransitionChild
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </TransitionChild>
                <div className="flex-shrink-0 flex items-center px-4">
                  <img
                    className="h-8 w-auto"
                    src={logo}
                    // src="https://tailwindui.com/img/logos/easywire-logo-cyan-300-mark-white-text.svg"
                    alt=" logo"
                  />
                </div>
                <nav
                  className="mt-5 flex-shrink-0 h-full divide-y divide-cyan-800 overflow-y-auto"
                  aria-label="Sidebar"
                >
                  <div className="px-2 space-y-1">
                    {navigation.map((item) => (
                      <Link
                        key={item.name}
                        to={item.to}
                        className={classNames(
                          item.current
                            ? "bg-cyan-800 text-white"
                            : "text-cyan-100 hover:text-white hover:bg-cyan-600",
                          "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                        )}
                        aria-current={item.current ? "page" : undefined}
                      >
                        <item.icon
                          className="mr-4 flex-shrink-0 h-6 w-6 text-cyan-200"
                          aria-hidden="true"
                        />
                        {item.name}
                      </Link>
                    ))}
                  </div>
                  <div className="mt-6 pt-6">
                    <div className="px-2 space-y-1">
                      {secondaryNavigation.map((item) => (
                        <Link
                          key={item.name}
                          to={item.to}
                          className="group flex items-center px-2 py-2 text-base font-medium rounded-md text-cyan-100 hover:text-white hover:bg-cyan-600"
                        >
                          <item.icon
                            className="mr-4 h-6 w-6 text-cyan-200"
                            aria-hidden="true"
                          />
                          {item.name}
                        </Link>
                      ))}
                    </div>
                  </div>
                </nav>
              </div>
            </TransitionChild>
            <div className="flex-shrink-0 w-14" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:flex lg:w-64 lg:flex-col lg:fixed lg:inset-y-0">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex flex-col flex-grow bg-cyan-700 pt-5 pb-4 overflow-y-auto">
            <div className="flex items-center flex-shrink-0 px-4">
              <img
                className="h-12 w-auto"
                src={logo}
                // src="https://tailwindui.com/img/logos/easywire-logo-cyan-300-mark-white-text.svg"
                alt="Easywire logo"
              />
            </div>
            <nav
              className="mt-5 flex-1 flex flex-col divide-y divide-cyan-800 overflow-y-auto"
              aria-label="Sidebar"
            >
              <div className="px-2 space-y-1">
                {navigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.to}
                    className={classNames(
                      item.current
                        ? "bg-cyan-800 text-white"
                        : "text-cyan-100 hover:text-white hover:bg-cyan-600",
                      "group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md"
                    )}
                    aria-current={item.current ? "page" : undefined}
                  >
                    <item.icon
                      className="mr-4 flex-shrink-0 h-6 w-6 text-cyan-200"
                      aria-hidden="true"
                    />
                    {item.name}
                  </Link>
                ))}
              </div>
              <div className="mt-6 pt-6">
                <div className="px-2 space-y-1">
                  {secondaryNavigation.map((item) => (
                    <Link
                      key={item.name}
                      to={item.to}
                      className="group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md text-cyan-100 hover:text-white hover:bg-cyan-600"
                    >
                      <item.icon
                        className="mr-4 h-6 w-6 text-cyan-200"
                        aria-hidden="true"
                      />
                      {item.name}
                    </Link>
                  ))}
                </div>
              </div>
            </nav>
          </div>
        </div>

        <div className="lg:pl-64 flex flex-col flex-1">
          <div className="relative z-10 flex-shrink-0 flex h-16 bg-white border-b border-gray-200 lg:border-none">
            <button
              type="button"
              className="px-4 border-r border-gray-200 text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-500 lg:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3CenterLeftIcon className="h-6 w-6" aria-hidden="true" />
            </button>
            {/* Search bar */}
            <div className="flex-1 px-4 flex justify-between sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
              <div className="flex-1 flex">
                <form className="w-full flex md:ml-0" action="#" method="GET">
                  <label htmlFor="search-field" className="sr-only">
                    Search
                  </label>
                  <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                    <div
                      className="absolute inset-y-0 left-0 flex items-center pointer-events-none"
                      aria-hidden="true"
                    >
                      <MagnifyingGlassIcon
                        className="h-5 w-5"
                        aria-hidden="true"
                      />
                    </div>
                    <input
                      id="search-field"
                      name="search-field"
                      className="block w-full h-full pl-8 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-0 focus:border-transparent sm:text-sm"
                      placeholder="Search transactions"
                      type="search"
                    />
                  </div>
                </form>
              </div>
              <div className="ml-4 flex items-center md:ml-6">
                <button
                  type="button"
                  className="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
                >
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button>

                {/* Profile dropdown */}
                <Menu as="div" className="ml-3 relative">
                  <div>
                    <Menu.Button className="max-w-xs bg-white rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 lg:p-2 lg:rounded-md lg:hover:bg-gray-50">
                      <img
                        className="h-8 w-8 rounded-full"
                        src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                        alt=""
                      />
                      <span className="hidden ml-3 text-gray-700 text-sm font-medium lg:block">
                        <span className="sr-only">Open user menu for </span>
                        {user?.firstName}
                      </span>
                      <ChevronDownIcon
                        className="hidden flex-shrink-0 ml-1 h-5 w-5 text-gray-400 lg:block"
                        aria-hidden="true"
                      />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700"
                            )}
                          >
                            Your Profile
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700"
                            )}
                          >
                            Settings
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700"
                            )}
                          >
                            Logout
                          </a>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>
          <main className="flex-1 pb-8">
            {/* Page header */}
            <div className="bg-white shadow">
              <div className="px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
                <div className="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-gray-200">
                  <div className="flex-1 min-w-0">
                    {/* Profile */}
                    {/* <div className="flex items-center">
                      <img
                        className="hidden h-16 w-16 rounded-full sm:block"
                        src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.6&w=256&h=256&q=80"
                        alt=""
                      />
                      <div>
                        <div className="flex items-center">
                          <img
                            className="h-16 w-16 rounded-full sm:hidden"
                            src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.6&w=256&h=256&q=80"
                            alt=""
                          />
                          <h1 className="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate">
                            Good morning, {user.firstName}
                          </h1>
                        </div>
                        <dl className="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
                          <dt className="sr-only">Company</dt>
                          <dd className="flex items-center text-sm text-gray-500 font-medium capitalize sm:mr-6">
                            <BuildingOfficeIcon
                              className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                            {user?.role}
                          </dd>
                          <dt className="sr-only">Account status</dt>
                          <dd className="mt-3 flex items-center text-sm text-gray-500 font-medium sm:mr-6 sm:mt-0 capitalize">
                            <CheckCircleIcon
                              className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                              aria-hidden="true"
                            />
                            Verified account
                          </dd>
                        </dl>
                      </div>
                    </div> */}
                  </div>
                  {/* <div className="mt-6 flex space-x-3 md:mt-0 md:ml-4">
                    <button
                      type="button"
                      className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
                    >
                      Add money
                    </button>
                    <button
                      type="button"
                      className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
                    >
                      Send money
                    </button>
                  </div> */}
                </div>
              </div>
            </div>

            <div className="mt-8">
             <div className="flex justify-start ">
             <h2 className="max-w-6xl mx-auto mt-8 px-4 text-lg leading-6 font-medium text-gray-900 sm:px-6 lg:px-0">
                All Users
              </h2>
              <h2 className="max-w-6xl mx-auto mt-8 px-4 text-lg leading-6 font-medium text-secondary sm:px-6 lg:px-8">
                Total Users: {userCounts}
              </h2>
             </div>


              {/* Activity list (smallest breakpoint only) */}
              <div className="shadow sm:hidden">
                {loading ? (
                  <div className="flex justify-center">
                    <h2>
                      <SpinnerIcon color="black" />
                    </h2>
                  </div>
                ) : (
                  <ul className="mt-2 divide-y divide-gray-200 overflow-hidden shadow sm:hidden">
                    {users.map((user) => (
                      <li key={user._id}>
                        <a
                          href={user.to}
                          className="block px-4 py-4 bg-white hover:bg-gray-50"
                        >
                          <span className="flex items-center space-x-4">
                            <span className="flex-1 flex space-x-2 truncate">
                              <BanknotesIcon
                                className="flex-shrink-0 h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                              <span className="flex flex-col text-gray-500 text-sm truncate">
                                <span className="truncate">
                                  {user?.firstName}
                                </span>
                                <span>
                                  <span className="text-gray-900 font-medium">
                                    {user.email}
                                  </span>{" "}
                                  {/* {transaction.currency} */}
                                </span>
                                <time dateTime={user.role}>
                                  {formatDate(user.role)}
                                </time>
                              </span>
                            </span>
                            <ChevronRightIcon
                              className="flex-shrink-0 h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </span>
                        </a>
                      </li>
                    ))}
                  </ul>
                )}

                <nav
                  className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200"
                  aria-label="Pagination"
                >
                  <div className="flex-1 flex justify-between">
                    <a
                      href="#"
                      className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500"
                    >
                      Previous
                    </a>
                    <a
                      href="#"
                      className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500"
                    >
                      Next
                    </a>
                  </div>
                </nav>
              </div>

              {/* Activity table (small breakpoint and up) */}
              <div className="hidden sm:block">
                <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
                  <div className="flex flex-col mt-2">
                    <div className="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded-lg">
                      {loading ? (
                        <div className=" w-full flex justify-center align-center">
                          (
                          <span className="">
                            <SpinnerIcon color="black" />
                          </span>{" "}
                          <span>Loading...</span>)
                        </div>
                      ) : (
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead>
                            <tr>
                              <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Email
                              </th>
                              <th className="px-6 py-3 bg-gray-50 text-start text-xs font-medium text-gray-500 uppercase tracking-wider">
                                First Name
                              </th>
                              <th className="px-6 py-3 bg-gray-50 text-start text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Last Name{" "}
                              </th>
                              <th className="px-6 py-3 bg-gray-50 text-start text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Phone Number
                              </th>

                              <th className="px-6 py-3 bg-gray-50 text-start text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Role
                              </th>

                              <th className="hidden px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider md:block">
                                Status
                              </th>
                            </tr>
                          </thead>
                          <tbody className="bg-white divide-y divide-gray-200">
                            {users.map((user) => (
                              <tr key={user._id} className="bg-white">
                                {/* <td className="max-w-0 w-full px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                 <div className="flex">
                                   <a href={transaction.href} className="group inline-flex space-x-2 truncate text-sm">
                                     <BanknotesIcon
                                       className="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                       aria-hidden="true"
                                     />
                                     <p className="text-gray-500 truncate group-hover:text-gray-900">
                                       {transaction.description}
                                     </p>
                                   </a>
                                 </div>
                               </td> */}
                                <td className="px-6 py-4 text-start whitespace-nowrap text-sm text-gray-500">
                                  <span className="text-gray-900 font-medium">
                                    {user?.email}{" "}
                                  </span>
                                  {/* {transaction.currency}\ */}
                                </td>
                                <td className="px-6 py-4 text-start whitespace-nowrap text-sm text-gray-500">
                                  <span className="text-gray-900 font-medium">
                                    {user?.firstName}{" "}
                                  </span>
                                  {/* {transaction.currency}\ */}
                                </td>
                                <td className="px-6 py-4 text-start whitespace-nowrap text-sm text-gray-500">
                                  <span className="text-gray-900 font-medium">
                                    {user?.lastName}{" "}
                                  </span>
                                  {/* {transaction.currency}\ */}
                                </td>
                                <td className="px-6 py-4 text-start whitespace-nowrap text-sm text-gray-500">
                                  <span className="text-gray-900 font-medium">
                                    {user.phoneNumber}{" "}
                                  </span>
                                </td>

                                <td className="px-6 py-4 text-start whitespace-nowrap text-sm text-gray-500">
                                  <span className="text-gray-900 font-medium">
                                    {user?.role}{" "}
                                  </span>
                                </td>

                                <td className="hidden px-6 py-4 whitespace-nowrap text-sm text-gray-500 md:block">
                                  <span
                                    className={classNames(
                                      statusStyles["verified"],
                                      "inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium capitalize"
                                    )}
                                  >
                                    Verified
                                  </span>
                                </td>

                                {/* <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                     

                       <button className='text-indigo-600 hover:text-indigo-900' onClick={() => downloadCertificate(transaction._id)}>Download Certificate</button>

                     </td> */}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}
                      {/* Pagination */}
                      <nav
                        className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
                        aria-label="Pagination"
                      >
                        <div className="hidden sm:block">
                          <p className="text-sm text-gray-700">
                            Showing <span className="font-medium">1</span> to{" "}
                            <span className="font-medium">10</span> of{" "}
                            <span className="font-medium">20</span> results
                          </p>
                        </div>
                        <div className="flex-1 flex justify-between sm:justify-end">
                          <a
                            href="#"
                            className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                          >
                            Previous
                          </a>
                          <a
                            href="#"
                            className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                          >
                            Next
                          </a>
                        </div>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
