import React from 'react'

import {
    CloudArrowUpIcon,
    CogIcon,
    LockClosedIcon,
    Bars3Icon,
    ArrowPathIcon,
    ServerIcon,
    ShieldCheckIcon,
    XMarkIcon,
  } from '@heroicons/react/24/outline'


const features = [
    {
      name: 'Power Derived from Nature\'s Bounty',
      description: 'We harness the power of renewable resources like biomass to create clean, sustainable energies solutions that won\'t deplete the planet\'s resources.',
      icon: CloudArrowUpIcon,
    },
    {
      name: 'Powering More Than Just Homes',
      description: 'from generating electricity to fueling vehicles, bioenergies provides a versatile solution for a wide range of energies needs.',
      icon: LockClosedIcon,
    },
    {
      name: 'Environmentally Friendly',
      description: 'Bioenergies offers a significant reduction in greenhouse gas emissions compared to traditional fossil fuels.',
      icon: ArrowPathIcon,
    },
    {
      name: 'Invest in the Future, Save Today',
      description: 'Bioenergies offers long-term cost benefits and potential government incentives. Discover how switching to bioenergies can save you money.',
      icon: ShieldCheckIcon,
    },
    {
      name: 'Innovation & Efficiency',
      description:
        'We are constantly innovating and improving bioenergies technology to maximize efficiency and minimize environmental impact',
      icon: CogIcon,
    },
    {
      name: 'Join the Bioenergies Movement',
      description: 'By choosing bioenergies, you\'re actively contributing to a more sustainable future. Join us and be part of the solution.',
      icon: ServerIcon,
    },
  ]
const Features = () => {
  return (
    <div className="relative bg-white py-16 sm:py-24 lg:py-32">
    <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
      <h2 className="text-base font-semibold tracking-wider text-cyan-600 uppercase">Careplanet energies Plc</h2>
      <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
      Bioenergies: Nature's Answer to Fossil Fuels
      </p>
      <p className="mt-5 max-w-prose mx-auto text-xl text-gray-500">
      Unplug from traditional energies and embrace a sustainable future.
      </p>
      <div className="mt-12">
        <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
          {features.map((feature) => (
            <div key={feature.name} className="pt-6">
              <div className="flow-root bg-gray-50 rounded-lg px-6 pb-8">
                <div className="-mt-6">
                  <div>
                    <span className="inline-flex items-center justify-center p-3 bg-gradient-to-r from-teal-500 to-cyan-600 rounded-md shadow-lg">
                      <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                    </span>
                  </div>
                  <h3 className="mt-8 text-lg font-medium text-gray-900 tracking-tight">{feature.name}</h3>
                  <p className="mt-5 text-base text-gray-500">{feature.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
  )
}

export default Features
