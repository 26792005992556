// utils/loadScript.js

export const loadMonnifyScript = () => {
    return new Promise((resolve, reject) => {
        const existingScript = document.getElementById('monnify-sdk');

        if (!existingScript) {
            const script = document.createElement('script');
            script.src = 'https://sdk.monnify.com/plugin/monnify.js';
            script.id = 'monnify-sdk';
            document.body.appendChild(script);

            script.onload = () => {
                resolve();
            };

            script.onerror = () => {
                reject(new Error('Failed to load Monnify SDK script.'));
            };
        } else {
            resolve();
        }
    });
};
