import { createContext, useState, useEffect } from "react";
import axios from "../utils/axios";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [auth, setAuth] = useState({});

  axios.defaults.withCredentials = true;

  const loginUser = (user, token) => {
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    setUser(user);
  };

  const logoutUser = async () => {
    try {
      setLoading(true);
      await axios.get("/auth/logout", {
        withCredentials: true,
        credentials: "include",
        method: "GET",
      });
      setUser(null);
    } catch (error) {
      console.log(error);
    }
  };


  const refreshToken = async () => {
    try {
      setLoading(true);
      const { data } = await axios.post("/token/refresh", {}, {
        withCredentials: true,
      });

      console.log('New access token data:', data);


      const refreshInterval = Math.max(data.expires_in * 1000 - 500, 5 * 60 * 1000); // 5 minutes minimum interval
      setTimeout(refreshToken, refreshInterval);
      // setTimeout(() => {
      //   refreshToken();
      // }, data.expires_in * 1000 - 500);

      setUser(data.user);
      axios.defaults.headers.common["Authorization"] = `Bearer ${data.accessToken}`;
    } catch (error) {
      console.error(error);
      // Handle error (e.g., redirect to login)
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    refreshToken();
    // eslint-disable-next-line
  }, []);

  // const refreshToken = async () => {
  //   try {
  //     setLoading(true);
  //     const { data } = await axios.get("/user/token/refresh", {
  //       withCredentials: true,
  //       credentials: "include",
  //       method: "GET",
  //     });

  //     setTimeout(() => {
  //       refreshToken();
  //     }, data.expires_in * 1000 - 500);

  //     setUser(data.user);
  //     axios.defaults.headers.common = { Authorization: `Bearer ${data.token}` };
  //   } catch (error) {
  //     console.log(error);
  //   }
  //   setLoading(false);
  // };

  // useEffect(() => {
  //   refreshToken();
  // }, []);

  let contextData = {
    user: user,
    loginUser: loginUser,
    logoutUser: logoutUser,
    loading,
    auth,
    setAuth,
  };

  return (
    <AuthContext.Provider value={contextData}>
      {loading ? null : children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
